import React from 'react';
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { DemoHeader } from "../commonComponents"

const styles = {
    fontContact: {
        color: "#fff",
        fontFamily: "DM Sans",
    },
    alignContent: {
        display: "flex",
        alignItems: "center",
    },
}

const myHTML = `
    <script src="https://frontend-api-server-v2.api.irisagent.com/v1/chatbot/bundle"></script>
  <script type='text/javascript'>
    initIrisAgentChatbot( "0d5a6c77ba1be27ebdf6d4c773acffd8e334a1a28f9979568e98d8e498d2" );
  </script>

<div style="display: flex; justify-content: center; height: 90vh;">
  <iframe src="https://app.irisagent.com/zendesk/zendesk-ticket-form-searchbar/getZendeskTicketFormSearchBar?businessId=af7fc5187f9e9109e80ae49e3fba877b6da79832" width="50%" style="border: 0;"></iframe>
</div>
<section id="contact" className="contact">
                    <div className="info-container" style={styles.fontContact}>

                    </div>

                </section>

`;

export default function AltiumDemo() {
    return (
        <div>
            <DemoHeader
                customer="ALTIUM"
            />
            <Helmet>
                <meta name={`robots`} content={`noindex, nofollow`} />
                <link
                    rel="canonical"
                    href="https://irisagent.com/altium-demo/"
                />
            </Helmet>
            <section className="inner-page" style={{paddingTop: "30px", paddingLeft: "20px"}}>
                <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            </section>

        </div>
    )
}
